import React from 'react';
import { route, mount, compose } from 'navi';
import {TopApp} from './TopApp';
// import {SoonApp} from './SoonApp';
import { Helmet } from 'react-navi-helmet-async';

export default compose(
  mount({
    '/': route({
      getView: ({ context }) => (
        <>
          <Helmet>
            <meta content={`${process.env.REACT_APP_URL}`} property="og:url" />
            <meta content={`${process.env.REACT_APP_OGP_URL}`} property="og:image" />
          </Helmet>
          <TopApp />
        </>
      ),
      title: process.env.REACT_APP_WEBSITE_NAME,
    }),
  }),
);
