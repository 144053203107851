import { createDocumentResource, createQueryResource, list, embed } from 'retil';
import axios from 'axios';
import { BaseURL, ApiKey, isMock } from './config';

export interface News {
  id: string;
  publishedAt: Date;
  title: string;
  content?: string;
  image?: { url: string };
}

export const news = createDocumentResource<News>('news');
export const newsList = createQueryResource('newsList', {
  for: list(news),
  load: async () => {
    if (isMock()) {
      return await mock();
    } else {
      return await fetchList();
    }
  },
});

export const newsItem = createQueryResource('newsItem', {
  for: embed(news, {}),
  load: async (id: string) => {
    return await fetchItem(id)
  },
});

function validate(source: any): News[] {
  return source.map((res: any) => {
    return {
      ...res,
      publishedAt: new Date(res.publishedAt),
    };
  });
}

async function mock(): Promise<News[]> {
  const { contents } = await import('../cache/news.json');
  return validate(contents as any);
}

async function fetchList(): Promise<News[]> {
  try {
    const res = await axios.get(BaseURL + `/news?limit=100`, {
      headers: {
        'content-type': 'application/json',
        'x-api-key': ApiKey,
      },
    });
    return validate(res.data.contents);
  } catch (error) {
    return [];
  }
}

async function fetchItem(id: string): Promise<News | null> {
  try {
    const res = await axios.get(BaseURL + `/news/${id}`, {
      headers: {
        'content-type': 'application/json',
        'x-api-key': ApiKey,
      },
    });
    return {
      ...res.data,
      publishedAt: new Date(res.data.publishedAt),
    };
  } catch (error) {
    return null;
  }
}
