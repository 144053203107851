import React from 'react';
import {route, mount, compose, redirect} from 'navi';
import {NewsApp} from './NewsApp';
import { Helmet } from 'react-navi-helmet-async';

export default compose(
    mount({
        '/': redirect("/"),
        '/:id': route({
            getView: ({ context, params }) => (
                <>
                    <Helmet>
                        <meta content={`${process.env.REACT_APP_URL}`} property="og:url" />
                        <meta content={`${process.env.REACT_APP_OGP_URL}`} property="og:image" />
                    </Helmet>
                    <NewsApp newsId={params.id} />
                </>
            ),
            title: process.env.REACT_APP_WEBSITE_NAME,
        }),
    }),
);
