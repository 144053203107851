import React from 'react';
import classNames from "classnames";
import { Link } from 'react-navi';
import { useResource } from 'retil';
import {Entries} from '@2021-contest-web/components/src/components';
import {Entry} from "@2021-contest-web/components/src/types";
import { StepBox } from '@2021-contest-web/components/src/components/StepBox';
import { Hero, Footer } from '../../components';
import {entryList} from "../../resources";
import {EntryCard} from "../../components/card";
import { newsList } from '../../resources/news';

export function TopApp() {
  const [entries] = useResource(entryList);
  const [news] = useResource(newsList);
  return (
    <main className="TopApp">
      {/*style={{paddingBottom: 80}}*/}
      <Hero />
      <section className="section is-padding-bottom-0">
        <div className="container" style={{maxWidth: 640}}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Finalist</h2>
          </div>
          {/*
          <figure className="image is-16by9">
            <iframe className="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/0Xq5BaQDQIA"
                    frameBorder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"

            />
          </figure>
          */}
        </div>
      </section>
      {/* FINALIST */}
      <section id="finalist" className="section">
        <div className="container">
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <Entries state={entries} noshuffle>
              {(list: Entry[]) =>
                  list.map((item: Entry) => (
                      <div
                          className={classNames('column is-3-tablet is-6-mobile')}
                          key={item.entry_id}
                      >
                        <EntryCard onlive={false} entry={item} href={`/profiles/${item.entry_id}`} />
                      </div>
                  ))
              }
            </Entries>
          </div>
        </div>
      </section>

      <section id="about" className="section as-about-section">
        <div className="container" style={{ maxWidth: 720 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
          </div>
          <p
              className="notification is-margin-bottom-3 is-size-7-mobile"
              style={{ margin: '0 auto' }}>
            Sophian's
            Contest（ソフィアンズコンテスト）は、上智大学で長年続いていた「ミス・ミスターソフィアコンテスト」を廃止し、
            <strong className="has-text-marker">2020年度から新設されました。</strong>
            <br />
            性別などの様々な違いにとらわれない「開かれたコンテスト」の開催を目指しています。
            <br />
            <br />
            全上智生から選ばれた候補者が、ソフィア祭までの活動期間の中で
            <strong className="has-text-marker">自身の魅力と社会課題を発信するインフルエンサーとしての活躍</strong>
            を競います。
            <br />
            <strong className="has-text-marker">SDGsを通して社会課題の認知度向上</strong>を目指しています。
            <br />
            <br />
            このコンテストでは、従来のミスミスターが持っていた課題を理解した上で、見える上智生として
            <strong className="has-text-marker">上智大学の魅力を伝え、社会で活躍する人財を輩出すること</strong>
            を目指しています。
          </p>
          <div className="theme-box has-text-centered">
            <div>
              <figure className="image is-margin-bottom-3">
                <img src="/img/bg/theme@2x.jpg" />
              </figure>
              <div className="is-margin-bottom-2">
                <p className="has-text-weight-bold is-size-7 is-margin-bottom-0-5">コンテストテーマ</p>
                <h3 className="is-size-3 is-size-4-mobile">
                  <figure className="image as-theme-logo" style={{ maxWidth: 240, margin: '0 auto' }}>
                    <img src="/img/theme.svg" />
                  </figure>
                </h3>
              </div>
              <p className="is-size-7-mobile has-text-centered has-text-weight-bold is-line-height-2-5">
                Sophian's Contest 2021の
                <br />
                テーマは「UNMUTE」です。
                <br />
                社会課題や興味関心のある事柄について
                <br />
                候補者一人ひとりが静寂を破り、
                <br />
                自分なりの発信をしていって欲しい
                <br />
                また候補者の活動をきっかけに、
                <br />
                誰もがより「UNMUTE」(発言)しやすい
                <br />
                世の中になっていって欲しい
                <br />
                という願いが込められています。
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* CONTENTS */}
      <section id="contents" className="section">
        <div className="container" style={{ maxWidth: 720 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Contents</h2>
          </div>
          <p
              className="notification has-background-white is-size-7-mobile"
              style={{ margin: '0 auto' }}>
            Sophian's
            Contest（ソフィアンズコンテスト）は、自身の魅力を発信する場として「自己PR部門」、社会課題を発信する場として「SDGs部門」の計二部門で最も多角的に活躍した人がグランプリ、準グランプリに輝きます。
          </p>
          <figure className="image is-margin-bottom-4 is-margin-top-2" style={{ maxWidth: 480, margin: '0 auto' }}>
            <img src="/img/chart.jpg" />
          </figure>
          <div className="columns is-centered is-multiline">
            <div className="column is-6">
              <StepBox
                  className="box"
                  style={{ maxWidth: '100%' }}
                  src="/img/step1.png"
                  alt={'自己PR部門'}
                  boxTitle={
                    <>
                      <span className="tag has-text-futura-pt has-text-italic is-size-4">1</span>
                      <br />
                      <br />
                      <strong className="has-text-marker">「自己PR部門」</strong>
                    </>
                  }
              >
                <p className="is-size-7-mobile">
                  候補者が自身の魅力を発信する部門。
                  <br />
                  事前web投票と当日の自己PRで受賞者を決定します。
                </p>
              </StepBox>
            </div>
            <div className="column is-12">
              <StepBox
                  className="box"
                  style={{ maxWidth: '100%' }}
                  src="/img/step3.png"
                  alt={'Step3 SDGs部門'}
                  boxTitle={
                    <>
                      <span className="tag has-text-futura-pt has-text-italic is-size-4">2</span>
                      <br />
                      <br />
                      <strong className="has-text-marker">「SDGs部門」</strong>
                    </>
                  }
              >
                <p className="is-size-7-mobile">
                  SNS上で自身の問題意識に沿った社会発信を行う中での参加意欲と社会発信力を競い、本選当日は候補者による発表での発信及び表現力を競う部門。<br />
                  事前審査と当日の発表で受賞者を決定します。
                  <br />
                  <br />
                  今年度は国連が定めた持続可能な開発目標SDGsに基づいた発信を行ないます。部門への参加意欲と社会発信度を合計し受賞者を決定します。
                  <br />
                  <br />
                  持続可能な開発目標（SDGs）とは，2015年9月の国連サミットで採択された「持続可能な開発のための2030アジェンダ」にて記載された2030年までに持続可能でよりよい世界を目指す国際目標です。17のゴール・169のターゲットから構成され，地球上の「誰一人取り残さない（leave
                  no one behind）」ことを誓っています。
                  SDGsは発展途上国のみならず，先進国自身が取り組むユニバーサル（普遍的）なものであり，日本としても積極的に取り組んでいます。(
                  <a
                      href="https://www.mofa.go.jp/mofaj/gaiko/oda/sdgs/about/index.html"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    外務省ホームページより引用
                  </a>
                  )
                </p>
                <figure className="image">
                  <img src="/img/bg/sdg_poster.jpg" />
                  <figcaption className="is-size-7 has-text-centered">
                    <a href="https://www.unic.or.jp/activities/economic_social_development/sustainable_development/2030agenda/sdgs_logo/">
                      出典: www.unic.or.jp
                    </a>
                  </figcaption>
                </figure>
              </StepBox>
            </div>
          </div>
        </div>
      </section>
      {/* EXHIBITION */}
      <section className="section" style={{ backgroundColor: '#FDFDFD' }}>
        <div className="container" style={{ maxWidth: 720 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Sophian's Exhibition</h2>
          </div>
          <div className="theme-box has-text-centered is-white">
            <div className="is-margin-bottom-3">
              <figure className="image is-margin-bottom-3">
                <img src="/img/bg/collaboration@2x.jpg" />
              </figure>
              <p className="is-size-7-mobile has-text-centered has-text-weight-bold is-line-height-2-5">
                今年度のコンテストでは<br />慶應義塾大学のサークル<strong className="has-text-marker">“Keio Fashion Creator”</strong>とコラボ。<br /><br />
                <strong className="has-text-marker">候補者の私物からリメイクされた衣装</strong>を身につけての<br />ファッションショーをお届けします。
              </p>
            </div>
            <div>
              <figure className="is-margin-bottom-1-5">
                <img src="/img/bg/keiofashioncreator@2x.jpg" width={240} height={240} />
              </figure>
              <h2 className="is-size-5 has-text-futura-pt has-text-italic has-text-centered is-margin-bottom-2">Keio Fashion Creator</h2>
              <p className="is-size-7-mobile has-text-centered has-text-weight-bold is-line-height-2-5">
                2002年に慶應義塾大学の「ファッションビジネス研究会」から独立する形で設立。2009年からESMOD JAPONと提携した授業を開始し、2010年から毎年ショーを開催している。2012年にインカレ化し、毎年合計で100名程度の部員が所属。社会に対する問いかけや主張を「服」や「ファッションショー」など独自のクリエイションを通じて表現する。
              </p>
            </div>

          </div>
        </div>
      </section>
      {/* OUTLINE */}
      <section className="section has-background-white-bis">
        <div className="container" style={{ maxWidth: 480 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Outline</h2>
          </div>
          <div className="columns is-mobile is-multiline">
            <div className="column is-5">
              <p className="title is-6">本選実施日時</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6">
                2021年11月3日(水)
              </p>
            </div>
            <div className="column is-5">
              <p className="title is-6">主催団体名</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6">ソフィア祭実行委員会コンテスト局</p>
            </div>
            <div className="column is-5">
              <p className="title is-6">Twitter</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6 has-text-futura-pt has-text-italic">
                <span className="has-text-marker">
                  <a href="https://twitter.com/sophianscontest">@sophianscontest</a>
                </span>
              </p>
            </div>
            <div className="column is-5">
              <p className="title is-6">Instagram</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6 has-text-futura-pt has-text-italic">
                <span className="has-text-marker">
                  <a href="https://www.instagram.com/sophianscontest">@sophianscontest</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* SPONSOR */}
      <section className="section">
        <div className="container" style={{ maxWidth: 480 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Special Sponsor</h2>
          </div>
          <div className="columns is-variable is-6 is-mobile is-multiline is-centered">
            <div className="column is-10">
              <figure className="image">
                <img src={`/img/special_sponsors/rize.jpg`} />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* SPONSOR */}
      <section className="section">
        <div className="container" style={{ maxWidth: 480 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Sponsor</h2>
          </div>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-6 has-text-centered">協賛</h2>
          </div>
          <div className="columns is-variable is-6 is-mobile is-multiline is-centered">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (<div className="column is-4">
              <figure className="image">
                <img src={`/img/sponsors/${index}.jpg`} />
              </figure>
            </div>))}
          </div>
        </div>
      </section>
      {/* MATERIAL SPONSOR */}
      <section className="section">
        <div className="container" style={{ maxWidth: 480 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-6 has-text-centered">物品協賛</h2>
          </div>
          <div className="columns is-variable is-6 is-mobile is-multiline is-centered">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((index) => (<div className="column is-4">
              <figure className="image">
                <img src={`/img/material_sponsors/S${index}.jpg`} />
              </figure>
            </div>))}
          </div>
        </div>
      </section>
      {/* NEWS */}
      <section id="news" className="section has-background-white-bis">
        {news.pending || news.data.length === 0 ? null : (
          <div className="container" style={{ maxWidth: 480 }}>
            <div className="is-margin-bottom-3">
              <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">News</h2>
            </div>
            <div id="news-columns" className="columns is-multiline is-mobile">
              {news.data.map(item => (
                <div key={item.id} className="column is-12">
                  <Link href={`/news/${item.id}`}>
                    <div className="box">
                      <article className="media">
                        <div className="media-content">
                          <div className="content">
                            <p>
                              <strong>{item.title}</strong>
                              <br />
                              <span className="has-text-futura-pt has-text-italic has-text-grey-light is-size-7">
                                {item.publishedAt.toDateString()}
                              </span>
                            </p>
                          </div>
                        </div>
                        {item.image && (
                          <div className="media-right">
                            <figure
                              className="image"
                              style={{
                                width: 110,
                                height: 60,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                backgroundColor: '#fafafa',
                                backgroundImage: `url(${item.image.url})`,
                              }}
                            >
                              <img className="is-hidden" width="160" alt="Image" />
                            </figure>
                          </div>
                        )}
                      </article>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
      <Footer />
    </main>
  );
}
