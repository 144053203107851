import { createDocumentResource, createQueryResource, list } from 'retil';
import { Entry } from '@2021-contest-web/components/src/types';

export const entry = createDocumentResource<Entry>('entry');
export const entryList = createQueryResource('entryList', {
    for: list(entry),
    load: async () => {
        return [
            {
                id: 1,
                entry_id: 1,
                block: 'A',
                stage: '1',
                name: 'ストルトン 絵美莉',
                "faculty": "国際教養学部 \n国際教養学科",
                "course": "",
                "grade": "3年",
                "birthday": "2001/01/21",
                "hometown": "カリフォルニア州サンディエゴ",
                "hobby": "趣味:裁縫 特技:英語、いつでもどこでも何時間でも寝れること",
                twitter: "Sophians21_no1",
                instagram: "sophians21_no1",
                interview1: "昨年度高校の先輩が出演しているのを見てソフィアンズコンテストの存在を知り、従来のミスコンと違い、SDGsの活動を重視したりジェンダーの枠に囚われない形のコンテストに惹かれて参加を決めました。",
                interview2: "SNSの発信です。自分の興味があるSDGsを積極的に発信して皆さんに見てもらいたいです！",
                interview3: "日本は安全で豊かな国と言われていますがその中でも難民や無国籍者が貧しい生活をしていたり不当に入管に収容されている人がいたり、家族と離れ離れになっている人もいることを知ってもらいたいです。",
                interview4: "メイクが得意です💄",
                interview5: "Sophia Refugee Support Group, Stateless Network Youth, 上智放送研究会",
                interview6: "表参道でアパレル店員してます",
                interview7: "国際教養学部の人はノリが良く、自分の意見を持っている人が多い！",
                interview8: "ガーデニングと料理",
                interview9: "池上彰",
                interview10: "人生の最も苦しい嫌な辛い損な場面を真っ先に微笑みを持って担当せよ",
                interview11: "グランプリももちろん獲りたいですが、SDGs賞を絶対に獲りたいです！最後まで気を抜かず一生懸命頑張ります！",
            },
            {
                id: 2,
                entry_id: 2,
                block: 'A',
                stage: '1',
                name: 'あさみ',
                "faculty": "文学部 \n新聞学科",
                "course": "生活社会科学講座",
                "grade": "4年",
                "birthday": "1996/02/28",
                "hometown": "",
                "hobby": "趣味は散歩。特技は、早起きです。",
                twitter: "Sophians21_no2",
                instagram: "sophians21_no2",
                interview1: "社会問題や自分自身の考えを発信できるというところが素敵だなと思い興味を持ちました。また、私自身が経験した学び直しや編入学のことを伝え、1人でも多くの方に「こんな選択肢もあるんだ」と伝えられたら嬉しいと思い志望しました。",
                interview2: "文章力や表現力を磨いて、少しでも多くの方にソフィアンズコンテストに興味をもって頂けるように情報を発信していく事を頑張りたいです!",
                interview3: "私は社会人を経験した後、22歳でフェリス女学院大学に入学しました。そしてフェリスで学んでいく中で、メディアやジェンダーに興味を持ち、3年次編入で上智大学の新聞学科に入学しています。だからこそ、「学び直しの楽しさ」や「人生の選択肢は沢山ある」という事を私自身の経験から伝えていきたいです。",
                interview4: "学び直し、編入学の経験",
                interview5: "",
                interview6: "スターバックス",
                interview7: "新聞学科では、テレビ制作の授業があります。番組を制作するために、企画、取材、ロケ、編集なども経験でき、実践的にメディアについて学べるところが自慢です。",
                interview8: "チロルチョコを毎日買う事",
                interview9: "エマ・ワトソン",
                interview10: "人の権利を奪う権利は誰にも無い",
                interview11: "25歳の大学生である私が今回のコンテストに出場できるのは、上智大学が「多様性」を大切にしているからこそだと思います。私はそんな上智大学が大好きですし、感謝でいっぱいです。だからこそ、コンテストを通して上智大学の魅了、学びの楽しさを伝えていくために頑張ります。",
            },
            {
                id: 3,
                entry_id: 3,
                block: 'A',
                stage: '1',
                name: '堤 奈々美',
                "faculty": "法学部 \n地球環境法学科",
                "course": "舞踊教育学コース",
                "grade": "3年",
                "birthday": "2000/01/01",
                "hometown": "東京都",
                "hobby": "コミュニケーションを取ること(アルバイトではお客様と積極的にコミュニケーションを取ることを心がけています！)\nダンス(高校はダンス部部長を努めていました！)\n水泳（小学生の時は毎日プールに通って、真っ黒に日焼けしていました！得意種目はバタフライです！)",
                twitter: "Sophians21_no3 ",
                instagram: "sophians21_no3",
                interview1: "自分自身の思いを自分の言葉で伝えることにより、社会問題に興味がある方を一人でも多く増やしたい。そのことを実現するためには、コンテストに出場して自分の情報発信の活動の場を広げることが有効だと思ったからです。",
                interview2: "SDGsの目標10（人や国の不平等を無くそう）、目標12（つくる責任、つかう責任）を中心に、様々なことに関心を向けて、あらゆる活動やその活動の背景についても自分の言葉で発信していけるよう頑張りたいと思います。自己PR部門では、手話ダンスのパフォーマンスなどにより、ありのままの自分を出すことができるよう頑張りたいと思います。",
                interview3: "私には、聴覚障がいをもつ姉が２人います。そのため、聴覚障がい者がかかえる様々な問題について、子供の頃から身近に関わってきました。いわゆるソーダ(聴こえない兄弟姉妹をもつ立場)としての自分自身の貴重な経験をもとに、一般の人が気づかない聴覚障がい者がかかえる問題について発信します。そして、多くの人に聴覚障がいへの理解を深めて頂けるよう、自分自身の言葉で伝えたいと思っています。また、パラリンピックやデフリンピックの出場選手へのインタビューなどの活動を通じて、社会の仲間として共感し、彼らの努力を知ることにより、そこから勇気を得て頂けるよう、彼らの活動や思いを伝えていきたいと思います。",
                interview4: "私自身、いつも人と人との繋がりのトリガーになることができます！\n初対面の人に対しても臆せず話しかける性格なので、いろいろな場面で、私を介して私の右の人と左の人が友達になれたりします！",
                interview5: "手話サークル｢てのひら｣",
                interview6: "カフェとレストランのホールで働いています!",
                interview7: "他の大学に比べて学生数が少ないので、学生と先生の距離感が近くなり、質問もしやすく、1人1人に丁寧に寄り添ってくださいます！！またキャンパス内では知り合いに会う確率が高く、友達の輪が広がりやすいと思います！",
                interview8: "料理（おうち時間が増え、料理がさらに好きになりました！冷蔵庫にあるものだけでレシピを考えることが得意です！）",
                interview9: "家族、友人\n精神的にたくましく、人に安心感を与え、周りから愛される人",
                interview10: "｢雨垂れ石を穿つ｣\n意味：小さな努力でも根気よく続けていれば、やがて大きなことを成し遂げられる\nコンテストでも、SNS更新やインタビュー活動という日々の小さな努力を積み重ねることにより、自分自身の成長や社会への貢献に繋げていければと思っています！！",
                interview11: "情報発信には、必ず責任が伴います。その責任をしっかりと意識し、取材や調査を重ね、皆様が社会問題やSDGｓに興味をもつきっかけとなれるように活動したいと思います。第2回ソフィアンズコンテスト、実行委員とファイナリスト5人で切磋琢磨して頑張りますので、応援よろしくお願いします！！",
            },
            {
                id: 5,
                entry_id: 5,
                block: 'A',
                stage: '1',
                name: '江川 真唯',
                "faculty": "総合グローバル学部 \n総合グローバル学科",
                "course": "",
                "grade": "3年",
                "birthday": "2000/11/23",
                "hometown": "東京都",
                "hobby": "趣味：サーフィン、ランニング、洋楽を聴くこと、ドライブ、アルバイト\n特技：初対面の人と仲良くなること、行動力、大食い！",
                twitter: "Sophians21_no5",
                instagram: "sophians21_no5",
                interview1: "一つは自分の興味関心や活動を社会に向けて発信したかったからです。二つ目は周りの友人からの推薦で、日頃から自身のSNSで社会課題について発信する中でさらに活動域を広げるためにもコンテストに出場してみようと思いました！",
                interview2: "SNSでの発信と、自分磨きを頑張りたいです！SNSでは私の日常や日々の取り組みなど、また身の回りのSDGsを発信することで少しでも多くの方に意識を持ってもらえるように頑張ります！また、コンテストをきっかけに自分自身も成長出来るように自分磨きに励みたいです！",
                interview3: "お伝えしたいことは沢山ありますが、大きく分けると「アフリカの魅力」「身の回りのSDGs」「夢を持つことの大切さ、素晴らしさ」です。自身のこれまでの活動をもとに私が思うそれぞれの気持ちをSNSや本番のスピーチでお伝えしたいです！",
                interview4: "私についてよく友達から言われるのは「太陽や向日葵のような存在で、周りの人を黄色で輝かせる才能がある」ことです！パッションと明るさは誰にも負けません！",
                interview5: "",
                interview6: "アパレル、ハンバーガー屋、スポーツ施設",
                interview7: "・国内外問わず色々なバックグラウンドを持った面白い人たちと関われる！\n・同じ学部なのに一人ひとりの興味分野や専攻が全く違う！\n・国際的で日々新たな視点を得ることができる！",
                interview8: "・友達とお喋りしながらゆったりサーフィンすること\n・クルーザー（スケボー）で街中散策をすること、サーフスケートにも挑戦中です！\n・好きなもののTiktokを載せること（友達との日常やオススメのカフェなど）\n・ドライブでお出かけすること（運転するのが好きです！）",
                interview9: "モデルのすみれさん",
                interview10: "「やらぬ後悔より、やる後悔」",
                interview11: "コンテストを通じて社会問題を発信すると共に、多くの方々を明るく笑顔に出来るように、自分らしく頑張りたいと思っております！応援よろしくお願い致します！",
            }
        ];
    },
});
